<template>
  <ul class="menu-nav">
<!-- --------------------------DASHBOARD------------------------------------ -->
    <router-link
        to="/home"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-chart-line"></i>
          <span class="menu-text">{{ $t('navigation.dashboard') }}</span>
        </a>
      </li>
    </router-link>
<!-- --------------------------APARTMENTS------------------------------------ -->
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-city"></i>
        <span class="menu-text">{{ $t('navigation.apartments') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">{{ $t('navigation.apartments') }}</span>
            </span>
          </li>

          <router-link
              to="/documents"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">
                  {{ $t('navigation.documents') }}
                  <span v-if="overdueCount" class="label label-danger ml-2">{{ overdueCount }}</span>
                </span>
              </a>
            </li>
          </router-link>
          <router-link
              to="/apartments"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t('navigation.my_apartments') }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
<!-- --------------------------FINANCES------------------------------------ -->
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-money-check-alt"></i>
        <span class="menu-text">{{ $t('navigation.finances') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">{{ $t('navigation.finances') }}</span>
            </span>
          </li>

          <router-link
              to="/transactions"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item my-2"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t('navigation.financial_turnover_to_the_contract_of_sale') }}</span>
              </a>
            </li>
          </router-link>

          <router-link
              to="/transactionsApartment"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item my-2"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t('navigation.financial_turnover_of_your_own_apartment') }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
<!-- --------------------------BOOKINGS------------------------------------ -->
    <router-link
        to="/bookings"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-calendar-alt"></i>
          <span class="menu-text">
            {{ $t('navigation.bookings_and_visits') }}
            <span v-if="newBookingsCount" class="label label-danger ml-2">{{ newBookingsCount }}</span>
          </span>
        </a>
      </li>
    </router-link>
<!-- --------------------------DEVICES------------------------------------ -->
    <router-link
        to="/devices"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-mobile"></i>
          <span class="menu-text">
            {{ $t('navigation.your_devices') }}
          </span>
        </a>
      </li>
    </router-link>
<!-- --------------------------SUPPORT------------------------------------ -->
    <router-link
        to="/support"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-comments"></i>
          <span class="menu-text">
            {{ $t('navigation.support') }}
            <span v-if="unreadMessagesCount" class="label label-danger ml-2">{{ unreadMessagesCount }}</span>
          </span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'KTMenu',
  beforeMount() {
    this.$store.dispatch('contractsStore/GET_OVERDUE_COUNT');
    this.$store.dispatch('notificationsStore/GET_COUNT');
  },
  computed: {
    ...mapGetters({
      overdueCount: 'contractsStore/OVERDUE_COUNT',
      newBookingsCount: 'notificationsStore/NEW_BOOKINGS_COUNT',
      unreadMessagesCount: 'ticketsStore/UNREAD_MESSAGES_COUNT'
    })
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  },
  beforeDestroy() {
    this.$store.commit('contractsStore/CLEAR_DATA');
    this.$store.commit('notificationsStore/SET_NEW_BOOKINGS_COUNT', 0);
    this.$store.commit('ticketsStore/SET_UNREAD_MESSAGES_COUNT', 0);
  }
};
</script>
