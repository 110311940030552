<template>
  <div class="topbar-item">
    <div
        class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
        id="kt_quick_user_toggle"
    >
      <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >{{ $t('label.hello') }}, </span>
      <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
        {{ computedName }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
<!--        <img v-if="false" alt="Pic" :src="currentUserPersonalInfo.photo" />-->
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{ computedFullNameAbbreviation }}
        </span>
      </span>
    </div>

    <div
        id="kt_quick_user"
        ref="kt_quick_user"
        class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
          class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          {{ $t('title.user_profile') }}
          <!--          <small class="text-muted font-size-sm ml-2">12 messages</small>-->
        </h3>
        <a
            href="#"
            class="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
          class="offcanvas-content pr-5 mr-n5 scroll"
          style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <!--            currentUserPersonalInfo.photo-->
            <img class="symbol-label" src="/media/users/blank.png" alt=""/>
          </div>
          <div class="d-flex flex-column">
            <router-link
                to="/profile"
                class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ computedFullName }}
            </router-link>
            <div class="navi mt-2">
              <div class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-muted">
                      <inline-svg src="/media/svg/icons/Communication/Mail.svg"/>
                    </span>
                  </span>
                  <span
                    v-if="user && user.email"
                    class="navi-text text-nowrap text-muted"
                    v-b-tooltip.hover.bottom="user.email"
                  >
                    {{ computedEmail }}
                  </span>
                </span>
              </div>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              {{ $t('btn.sign_out') }}
            </button>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import {mapGetters} from 'vuex';
import {LOGOUT} from '@/store/core/auth.module';
import KTLayoutQuickUser from '@/assets/js/layout/extended/quick-user.js';
import KTOffcanvas from '@/assets/js/components/offcanvas.js';
import i18nService from '../../../services/i18n.service';

export default {
  name: 'KTQuickUser',
  data() {
    return {
      list: [
        {
          title: 'Another purpose persuade',
          desc: 'Due in 2 Days',
          alt: '+28%',
          svg: 'media/svg/icons/Home/Library.svg',
          type: 'warning'
        },
        {
          title: 'Would be to people',
          desc: 'Due in 2 Days',
          alt: '+50%',
          svg: 'media/svg/icons/Communication/Write.svg',
          type: 'success'
        },
        {
          title: 'Purpose would be to persuade',
          desc: 'Due in 2 Days',
          alt: '-27%',
          svg: 'media/svg/icons/Communication/Group-chat.svg',
          type: 'danger'
        },
        {
          title: 'The best product',
          desc: 'Due in 2 Days',
          alt: '+8%',
          svg: 'media/svg/icons/General/Attachment2.svg',
          type: 'info'
        }
      ]
    };
  },
  async beforeMount() {
    await this.$store.dispatch('usersStore/GET_USER');
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs['kt_quick_user']);
  },
  computed: {
    ...mapGetters({
      user: 'usersStore/USER'
    }),

    computedName() {
      return this.user?.name;
    },
    computedFullNameAbbreviation() {
      return this.user ? this.user.name.charAt(0) + '.' + this.user.last_name.charAt(0) + '.': '';
    },
    computedFullName() {
      return `${this.user?.name} ${this.user?.last_name}`;
    },
    computedEmail() {
      let email = this.user?.email;

      if (email && email.length > 17) {
        let parts = email.split('@');
        if (parts.length === 2) {
          let username = parts[0];
          let domain = parts[1];
          let beginning = username.substring(0, 4);
          let ending = domain.length > 6 ? domain.substring(domain.length - 6) : domain;
          email = `${beginning}***${ending}`;
        }
      }

      return email;
    },
  },
  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT);
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    }
  },
};
</script>

<style lang="scss">
.quick-panel-right[data-offcanvas-offcanvas="on"] {
  .tooltip-inner {
    white-space: nowrap;
  }
}
</style>