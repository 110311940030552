<template>
  <ul class="menu-nav">

    <router-link
        to="/home"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">{{ $t('navigation.dashboard') }}</span>
        </a>
      </li>
    </router-link>

    <router-link
        to="/apartments"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">{{ $t('navigation.my_apartments') }}</span>
        </a>
      </li>
    </router-link>

    <router-link
        to="/support"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">{{ $t('navigation.support') }}</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
};
</script>
