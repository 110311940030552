<template>
  <div class="topbar-item">
    <div
        class="btn btn-icon btn-clean btn-lg mr-1"
        :class="{'pulse pulse-primary': notifications.length}"
        id="kt_quick_panel_toggle">
      <span class="svg-icon svg-icon-xl svg-icon-primary">
        <inline-svg src="/media/svg/icons/General/Notifications1.svg"/>
      </span>
      <span v-if="notifications.length" class="pulse-ring"></span>
    </div>

    <!-- begin::Quick Panel -->
    <div
        id="kt_quick_panel"
        ref="kt_quick_panel"
        class="offcanvas offcanvas-right pt-5 pb-10"
        style="overflow: hidden;"
    >
      <!--begin::Header-->
      <div
          class="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5"
      >
        <ul
            class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
            role="tablist"
        >
          <li class="nav-item">
            <a
                class="nav-link active"
                data-tab="1"
                data-toggle="tab"
                href="#"
                role="tab"
                aria-selected="false"
            >
              {{ $t('notification.notifications') }}
            </a>
          </li>
        </ul>
        <div class="offcanvas-close mt-n1 pr-5">
          <a
              href="#"
              class="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_panel_close"
          >
            <i class="ki ki-close icon-xs text-muted"></i>
          </a>
        </div>
      </div>
      <!--end::Header-->

      <div class="offcanvas-content px-10">
        <div class="tab-content">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <div class="scroll pr-7 mr-n7" id="kt_quick_panel_notifications">
                <!--begin::Nav-->
                <div v-if="notifications.length" class="navi navi-icon-circle navi-spacer-x-0">
                  <!--begin::Item-->
                  <div v-for="notification in notifications" class="navi-item cursor-pointer">
                    <div class="navi-link rounded" @click="notificationClickAction(notification)">
                      <div class="symbol symbol-50 mr-3">
                        <div class="symbol-label">
                          <i class="icon-lg far" :class="getNotificationIconClass(notification.type)"/>
                        </div>
                      </div>
                      <div class="navi-text">
                        <div class="d-flex justify-content-between font-weight-bold font-size-lg">
                          {{ notification.msg }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end::Item-->
                </div>
                <div v-else>
                  <p class="text-center text-muted mt-7">{{ $t('notification.no_new_notifications') }}</p>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <!-- end::Quick Panel -->
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import KTLayoutQuickPanel from '@/assets/js/layout/extended/quick-panel.js';
import KTUtil from '../../../../assets/js/components/util';

export default {
  name: 'KTQuickPanel',
  data() {
    return {
      tabIndex: 0,
    };
  },
  beforeMount() {
    this.getNotifications();
  },
  mounted() {
    KTLayoutQuickPanel.init(this.$refs["kt_quick_panel"]);
  },
  computed: {
    ...mapGetters({
      notifications: 'notificationsStore/NOTIFICATIONS'
    })
  },
  methods: {
    notificationClickAction(notification) {
      let visitedState = false;
      if (notification.type === 'persCab.booking') {
        visitedState = true;
        this.$router.push({name: 'bookingsList', query: {booking_id: notification.bookingId}});
      } else if (notification.type === 'backOffice.ticket') {
        visitedState = true;
        this.$router.push({name: 'support'});
      }

      this.$store.dispatch('notificationsStore/SET_NOTIFICATION_AS_READ', notification.id)
        .then(() => {
          this.$store.dispatch('notificationsStore/GET_NOTIFICATIONS');
        });

      if (visitedState) document.getElementById('kt_quick_panel_close').click();
    },
    getNotificationIconClass(type) {
      let result = '';
      switch (type) {
        case 'persCab.booking':
          result = 'fa-calendar-alt text-warning';
          break;
        case 'backOffice.ticket':
          result = 'fa-comments text-primary';
          break;
        default:
          result = 'fa-exclamation-circle text-danger';
          break;
      }

      return result;
    },
    getNotifications() {
      this.$store.dispatch('notificationsStore/GET_NOTIFICATIONS');
    }
  }
};
</script>
